/*s1 239 It is time you own and control your digital identity and personal data */
.s1 {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 37px;

    color: #FFFFFF;
}

/* Join the white list */
.s2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 37px;
    text-transform: uppercase;
    cursor: pointer;
    fill: #080B20;
}

.s2:hover {
	fill: white;
}

/* Identifire provides a digital identity KYC solution that verifies and validates your identity in the digital world using NFT and blockchain technology. */
.s3 { 
/*position: absolute;
width: 872px;
height: 51px;
left: 524px;
top: 1301px;*/

font-family: 'Exo';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 29px;
text-align: center;

color: #FFFFFF;
}

.oi2w {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
}

 /* Through the use of Blockchain technology, we can make one-time registration of ownership in a digital file consisting of PII combined with Regulatory identification known as KYC (Your Know Client) -  PII personal information is encrypted on a token.  */
.oitwdesc {

font-family: 'Exo';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 31px;

color: #FFFFFF;


}

/* Our minting process uses the SOL BLOCKCHAIN architecture ("SOL" / "SOLNA"). SOL is a state-of-the-art blockchain infrastructure It enables a large amount of drawings to be made quickly, and the mining process does not require a great deal of electricity. */
.sb  {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    color: #FFFFFF;
}


.filter1 {
    /* Rectangle 60 */
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 20px;
}

.buttonJoin {
    /* Greenblue-glow */
    background: linear-gradient(101.27deg, #13FCAA 9.53%, #13FCFC 73.31%);
    box-shadow: 10px 10px 50px rgba(31, 160, 99, 0.5);
    border-radius: 50px;
}

/* The only way to secure personal Identities in Web.3 */
.towts {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 55px;
    text-align: center;

    /* Greenblue-glow */
    background: linear-gradient(101.27deg, #13FCAA 9.53%, #13FCFC 73.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

/* Follow us on social or leave just your email so you can be one of the firsts to tokenize your personal data */
.dlyndesc {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
    fill: #FFFFFF;
}

    /* Don’t Leave Your Name and Number! */
.dlynanhead {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    fill: #FFFFFF;
}

 /* Regain Control of Your Digital Identity */
.regain {

font-family: 'Exo';
font-style: normal;
font-weight: 900;
font-size: 80px;
line-height: 115.4%;
/* or 92px */
text-align: center;

/* Greenblue-glow */
background: linear-gradient(101.27deg, #13FCAA 9.53%, #13FCFC 73.31%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}

    /* Open your Phantom wallet */
.connecttowallet {
    font-family: 'Exo';
    font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 29px;
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
	color: #080B20;
	
	text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

}

.FConnectToWalletrect {
	cursor: pointer;
}

 /* Solving Web.3’s Identity Crisis */
.solving {
 
font-family: 'Exo';
font-style: normal;
font-weight: 800;
font-size: 45px;
line-height: 55px;
text-align: center;

/* Greenblue-glow */
background: linear-gradient(101.27deg, #13FCAA 9.53%, #13FCFC 73.31%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;

}

    /* Get on the wait list */
.gwl {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    color: #080B20;

    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.solvingBoxes {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
}

    /* Your ID token is saved by you and not with a third party */
.regaindesc {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
}

.regaindesctitle {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
}

.FSocialLink {
	cursor: pointer;
}

.FSolanaImage {
	fill: red;
    width: 62px;
    height: 62px
}

.FEmailInputfg {
	position: 	absolute;
	width: 		400px;
	height: 	48px;
	color: 		#080b20;
	border: none;
	background-color:var(--var-disable-color);
	cursor: not-allowed;
}

.ForeignObjectBodyWrapper {
	padding: 0px 0px 10px 0px;
	height: 	53px;
	 
	border: none;	
	background-color:var(--var-disable-color);
}

.FEmailInput {
	margin-top: 0px !important;
	margin-left: 5px;
	outline: none;
	font-size: 24px;
	height: 	48px;
	width: 100%;
	border: none;
	line-height: 30px;
	background-color:var(--var-disable-color);
}

/* Copyright Identifire 2022 */
.hcopyright {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 29px;
	text-align: center;
	color: #000000;
}

.siteContent {
	overflow: hidden;
}

.registerButton {
	 color: #080B20;
	 font-weight: 400;
	 font-size: 24px;
	 cursor: pointer;
}

.registerButton[valid="false"] {
	color: white;
	font-weight: 100;
}

.registerButton[valid="true"].registerButton[inprogress="true"] {
	fill: red !important;
	animation: progressanim 2s linear infinite;
}

.FAnswerContainer {
	border: 3px solid;
	border-image-source: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */
	border-radius: 20px;
}

.FActionButton {
	background-color: red;
	background: linear-gradient(101.27deg, #13FCAA 9.53%, #13FCFC 73.31%);
	box-shadow: 10px 10px 50px rgba(31, 160, 99, 0.5);
	border-radius: 50px;
	text-align:center;
	border: 5px solid #FFFFFF;
	cursor: pointer;
}

.FActionButton[isvalid="true"]:hover {
	background: linear-gradient(59.58deg, #9A55F1 23.26%, #006BFF 105.52%);
	border: 5px solid #FFFFFF;
	border-radius: 50px;
}

.FActionButton[isvalid="false"]:hover {
	cursor: not-allowed;
}

.FButtonSignUpInner {
	font-weight: 600;
	font-size: 28px;
	line-height: 37px;
	text-align: center;
	text-transform: uppercase;
	color: #080B20;
	cursor: pointer;
}

.FButtonSignUpInner:hover {
	font-weight: 600;
	font-size: 28px;
	line-height: 37px;
	text-align: center;
	text-transform: uppercase;
	color: #FFFFFF;
}

.FActionRegister {
	height: 63px;
	color: white !important;	
}

.FActionButtonLabel {
	font-weight: 600;
	font-size: 30px;
	line-height: 29px;
	align-self: center;
	text-align: center;
	text-transform: uppercase;
	color: #080B20;
	text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	line-height: 2.8em;;
}

.FLinkedInLink {
	cursor: pointer;
}

.FActionButtonLabelReg {
	font-weight: 600;
	font-size: 22px;
	line-height: 29px;
	text-align: center;
	text-transform: uppercase;
	color: #080B20;
	text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	line-height: 2.8em;;
}

.FGBody {
	padding: 0 !important;
}
.FActionButton[isvalid="false"] > .FActionButtonLabel {
 	cursor: not-allowed;
}

.FQuestionAndAnswer[issafari="false"] {
	width: 753px;
	height: 45px;
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	color: #080B20;
	text-align: center;
	display: grid;
	grid-template-columns: 705px 48px;
	z-index: 100;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
	grid-row: 1;
	z-index: 100;
}

.FQuestionAndAnswer[issafari="true"] {
	width: 450px;
	height: 26px;
	max-height: 26px;
	background: rgba(255, 255, 255, 0.9);
	margin: 0px 0px 0px 0px !important;
	padding: 0px 0px 12px 0px;
	overflow: hidden;
	font-size: 22px;
	text-align: center;
	border-radius: 20px;
}

.FQAImage[issafari="true"] {
	position: relative;
	width: 30px;
	height: 30px;
	float: right;
	top: 4px;
	right: 6px;
 
}

.FQAImage {
	width: 48px;
	height: 48px;
	cursor: pointer;
}

.FQALabel {
	font-weight: 500;
	font-size: 18px;
	text-align: center;
	letter-spacing: 0.035em;
	color: #080B20;
	line-height: 48px;
	cursor: pointer;
}

.FAnswer {
	display: none;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.035em;
	color: #555658;
	background: rgba(255, 255, 255, 1);
	border:none;
	margin-left: 41px;		
	margin-right: 41px;
}

.FQAContainer[issafari="false"].FQAContainer[isselected="false"] {
	position: fixed;
	background: rgba(255, 255, 255,1);
	border-radius: 20px;
}

.FQAContainer[issafari="false"].FQAContainer[isselected="true"] {
	background: rgba(255, 255, 255,1);
	height: 280px;
}

.FQAContainer[issafari="true"].FQAContainer[isselected="false"] {
	will-change: auto;
	position: fixed;
	width: 770px;
	min-width: 770px;	
	font-size: 18px;
}

.FQAContainer[issafari="true"].FQAContainer[isselected="true"] {
	will-change: auto;
	  -webkit-box-pack: center;
	background: white;
	position: fixed;
	width: 450px !important;
	min-width: 450px !important;
	max-width: 450px !important;
	min-height: 300px;
	max-height: 300px;
	height: 	300px;
	border-radius: 20px;
}

.FAnswer[issafari="false"] {	
	text-align: center;
	display:block;
	width: calc(100% - 82px);
	max-width: calc(100% - 82px);
	min-width: calc(100% - 82px);
	margin-top: -10px;
}

.FAnswer[issafari="true"].FAnswer[isselected="true"] {
	position: relative;
	display: block;
	text-align: center;
	margin-top: -10px;
	margin-left: 41px;		
	margin-right: 41px;
	width: 405px;
	max-width: 405px;
	min-width: 405px;
	background: lightfray;
	
}

.FAnswer[issafari="true"].FAnswer[isselected="false"] {
	height: 200px;
	display: none;
    background: white;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: 0.035em;
	color: #555658;
	background: rgba(255, 255, 255, 1);
	border:none;
}

.FAnswPara[issafari="true"].FAnswPara[isselected="true"] {
	position: relative;
	display: block;
	width: 380px;
	max-width: 380px;
	min-width: 380px;
	font-size: 18px;
	color: #555658;
	height: 250px;
}
.FQuestionAndAnswer[isselected="true"] {
	box-shadow: none;
}

.FAnswer[isselected="true"] {
	display: block;
}

.emailInputError {
	width: 500px;
	min-width: 500px;
	height: 45px;
	font-weight: 700;
	font-size: 22px;
	line-height: 29px;
	text-align: center;
	color: #7F60FF;	
}

.FOQA {
	height: 68px;
	border-radius: 20px;
	width: 770px;	
}

.FOQA[isselected="true"] {
	height: 200px;	
}

.FOQA[issafari="true"].FOQA[isselected="true"] {
	height: 200px;
}

@-moz-document url-prefix() {
.FActionButton {
	background: -moz-linear-gradient(left 20deg, black, white);
	box-shadow: 10px 10px 50px rgba(31, 160, 99, 0.5);
	border-radius: 50px;
	text-align:center;
	border: 5px solid #FFFFFF;
	cursor: pointer;
	}
	
.FActionButton[isvalid="true"]:hover {
	background:  -moz-linear-gradient(59.58deg, #9A55F1 23.26%, #006BFF 105.52%) !important;
	border: 5px solid #FFFFFF !important;
	border-radius: 50px !important;
}

.FActionRegister {
   height: 46px; 
   color: white !important;	
}

.FActionButtonLabel {
	margin-bottom: 10px;
	line-height: 46px;
}

}


@keyframes progressanim {
	 0% {
        fill: red !important;
     }
     25% {
      fill: #080B20  !important;
     }
     50% {
      fill: red !important;
     }
     
     100% {
         fill: #080B20 !important;
     }
}
