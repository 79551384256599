@import url('https://fonts.googleapis.com/css2?family=Exo:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@800&display=swap');

.App {
 	height: 100%;
}

.FDisable {
	background: var(--var-disable-color);
	border-radius: 36px;
	color: white;
	cursor:not-allowed !important; 
}

.HBgColor {
	width: 100%;
	height: auto;
	max-height: calc(100% - 20vh);
}
.FPowerd {
	font-family: 'Exo';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 37px;
	letter-spacing: 0.2em;
    fill: white;
}
.FMSG {
	font-size: 60px !important;
}

.FCreateButton {
	height: 90%;
	border-radius: 50px;	
	display: grid;
}
.FCreateButton:hover {
	border-radius: 50px;	
}

.FActionCreateButtonLabel {
	align-self: center;
	font-size: 28px;
	margin-left: 0.2em;
 	 
	vertical-align: middle;
}

.FAlertPopup {
		position: fixed;
		top: 2em;
		left: 2em;
		width: auto;
		height: 5em;
		background: transparent; 
		z-index: 2000;
		text-align: center;
}

.FAlertPopupImage {
	width: 50%;
	height: auto;
}

.FPopupcloseicon {
	color: black;
	position: fixed;
	top:5em;
	left: 5em;
	cursor: pointer;
}

.HPageLayout {
	height: 100%;
	display: grid;
	grid-template-columns: 50% 50%;
  	grid-template-rows: 10% 50% 10% 10% 10% 10%;
}

.HTitleContainer {
	grid-row: 2;
	grid-column: 2;
	text-align: center;
}

.HTitle {
	color: var(--var-color-orange);
	font-size: 80px;
	margin: 0px;
}

.HDesc {
		color: var(--var-text-color);
		grid-row: 3;
		grid-column: 2;
		text-align: justify;
		font-size: 20px;
}

.ITEXTCounter {
	
	width: 57px;
	height: 97px;
	
	font-family: 'Exo';
	font-style: normal;
	font-weight: 800;
	font-size: 100px;
	line-height: 115.4%;

	/* or 115px */
	text-align: center;

	color: #FFFFFF
}

.bodyContainer {
	padding: 0em;
	overflow: auto;
	overflow-x: hidden;
	background-color: #080B20	;
}

.HBigLogoContainer {
	grid-row: 2;
	grid-column: 1;
	width: 100%;
	height: 100%;
	text-align: center;
}

.HContact {
		color: var(--var-text-color);
		grid-row: 5;
		grid-column: 1 /  span 2;
		display: inline-flex;
		font-size: 24px;
		text-align:center;
	}
	
	.HLink{
		color: var(--var-color-orange);
		cursor: pointer;
		margin-top: 0.8em;
	}
	
	.HPhone {
		color: var(--var-color-orange);
		margin-right: 0.5em;
		margin-top: 1em;
	}
	.HCopyright {
	    color: var(--var-color-orange);
		grid-row: 6;
		grid-column: 1 /span 2;
		font-size: 16px;
		text-align:center;
		display: block;
	}
	
	.FAffiliate {
		 fill: none;
		 stroke: #13FCAA;
		 stroke-width:25px;
		 stroke-linejoin: round;
	}
	
	.FAffiliateContent {
		cursor: pointer;
	}

@media only screen and (min-width: 600px) and (max-width: 800px) {
	.HTitle {
		color: var(--var-color-orange);
		font-size: 60px;
	}
	
	.HPageLayout {
		height: 100%;
		display: grid;
		grid-template-columns: 100%;
  		grid-template-rows: 5% 30% 15% 30% 8% 12%; 
	}
	
	.HBigLogoContainer {
		grid-row: 2;
		grid-column: 1;
	}
	
	.HTitleContainer {
		grid-row: 3;
		grid-column: 1;
		text-align: center;
	}
	
	.HDesc {
		color: var(--var-text-color);
		grid-row: 4;
		grid-column: 1;
		text-align: center;
	}
	
	.HTitle {
		color: var(--var-color-orange);
		font-size: 50px;
		margin: 0px;
	}
	
	.HContact {
		color: var(--var-text-color);
		grid-row: 5;
		grid-column: 1 /  span 2;
		display: inline-flex;
		font-size: 12px;
		text-align:center;
	}
	
	.HLink{
		color: var(--var-color-orange);
		cursor: pointer;
		margin-top: 0.8em;
	}
	
	.HPhone {
		color: var(--var-color-orange);
		margin-right: 0.5em;
		margin-top: 0.9em;
	}
	.HCopyright {
	    color: var(--var-color-orange);
		grid-row: 6;
		grid-column: 1;
		font-size: 12px;
		text-align:center;
		display: block;
	}
}

@media only screen and (max-width: 600px) {
	.HPageLayout {
		height: 100%;
		display: grid;
		grid-template-columns: 100%;
  		grid-template-rows: 10% 50% 20% 10%;
	}
	
	.HBigLogoContainer {
		grid-row: 2;
		grid-column: 1;
	}
	
	.HTitleContainer {
		grid-row: 1;
		grid-column: 1;
		text-align: center;
	}
	
	.HTitle {
		color: var(--var-color-orange);
		font-size: 50px;
		
	}
	
	.HDesc {
		color: var(--var-text-color);
		grid-row: 3;
		grid-column: 1;
		text-align: justify
	}
	
	.HContact {
		color: var(--var-text-color);
		grid-row: 4;
		grid-column: 1 /  span 2;
		display: inline-flex;
		font-size: 12px;
		text-align:center;
	}
	
	.HLink{
		color: var(--var-text-color);
		cursor: pointer;
	}
	
	.HPhone {
		color: var(--var-color-orange);
		margin-right: 0.5em;
		margin-top: 1em;
	}
	
	.HLink {
		color: var(--var-color-orange);
		margin-top: 1em;
	}
	.HCopyright {
	    color: var(--var-color-orange);
		grid-row: 6;
		grid-column: 1;
		font-size: 12px;
		text-align:center;
		display: block;
	}
	
	
	
}